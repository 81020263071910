<template>
  <div>
    <b-table
      class="table table-striped table-hover"
      :fields="fields"
      :items="listaPacienteAtendidos"
      :per-page="porPagina">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
          <strong>Cargando...</strong>
        </div>
      </template>
      <template #cell(correlativo)='items'>
        {{ items.index + 1 }}
      </template>
      <template #cell(fecha_registro)="row">
        {{ formatDate(row.item.fecha_registro) }}
        <span style="color:blue">
            {{ getTime(row.item.fecha_registro) }}
        </span>
      </template>
      <template #cell(print)='items'>
        <i
          class="nav-icon i-File-Download font-weight-bold"
          :class="hasPermisionImprimirConsultaExterna? 'text-info':''"
          style="cursor: pointer"
          @click="openModalPrint(items.item)"
        >
        </i>
      </template>
    </b-table>
    <modalPrint :itemService="itemServicio" :selectedConsulta="selectedId"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import modalPrint from './modalPrint.vue';

export default {
  name: 'TheTablePaciente',
  data() {
    return {
      fields: [
        {
          key: 'correlativo', label: 'N°', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '6%' },
        },
        {
          key: 'numero', label: 'N° C.E', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '6%' },
        },
        {
          key: 'fecha_registro', label: 'Fecha', tdClass: 'text-12 px-0', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '13%' },
        },
        {
          key: 'tipo_consulta', label: 'Tipo', tdClass: 'text-12 px-0', thClass: 'th-custom-color', class: 'text-center px-0', thStyle: { width: '13%' },
        },
        {
          key: 'medico', label: 'Medico', thClass: 'th-custom-color', class: 'text-center'/* , thStyle: { width: '17%' } */,
        },
        {
          key: 'motivo', label: 'Motivo', tdClass: 'text-12 px-0', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '13%' },
        },
        {
          key: 'diagnostico1', label: 'Diagnostico', tdClass: 'text-12 px-0', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '13%' },
        },
        {
          key: 'print', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
      ],
      selectedId: null,
      itemServicio: null,
    };
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      const match = dateString.match(/(\d{2}\/\d{2}\/\d{4})/);
      return match ? match[0] : '';
    },
    getTime(dateString) {
      if (!dateString) return '';
      const match = dateString.match(/(\d{2}:\d{2})/);
      return match ? match[0] : '';
    },
    async openModalPrint(item) {
      if (this.hasPermisionImprimirConsultaExterna) {
        this.selectedId = item;
        // eslint-disable-next-line no-unused-vars
        await this.getServicioComplementario(item.consulta_id);
        this.$bvModal.show('modalPrintConsultaExterna');
      }
    },
    async getServicioComplementario(item) {
      try {
        const RESPONSE = await axios.get(`clinic/consulta_externa/${item}/get_categoria_servicio_complementarios`);
        this.itemServicio = RESPONSE.data.data.categoria_servicio_complementarios;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    ...mapGetters('main', [
      'hasPermisionImprimirConsultaExterna',
    ]),
    ...mapFields('ConsultaExternaPaciente', [
      'porPagina',
      'listaPacienteAtendidos',
    ]),
  },
  components: {
    modalPrint,
  },
};
</script>
